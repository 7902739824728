import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import { newPostNewBlog, uploadBlogImage, addNewBlog } from '../../actions/blogActions'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import PeopleIcon from '@material-ui/icons/People'
import PublicIcon from '@material-ui/icons/Public'
import AlarmIcon from '@material-ui/icons/Alarm'
import AttachMoney from '@material-ui/icons/AttachMoney'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getAppBaseUrl } from '../../actions/api'
import moment from 'moment'
import { getFileExtension } from '../../utils/common'
import WebsiteFontColor from './WebsiteFontColor'
import CardRow from './CardRow'
import ModalPopUp from './ModalPopUp'
import NewGalleryMultiImageUpload from '../gallery/NewGalleryMultiImageUpload'
import axios from 'axios'
import {
    galleryReset,
    updateGalleryProgress,
    dragDropImage,
    previewGalleryReset,
    setIsPreviewModeEnable,
    switchGallery
} from '../../actions/galleryActions'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import classNames from 'classnames'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import { setGoogleAnalyticsEvent } from '../../utils/GoogleAnalyticsEvent'
import { updateMediaCountInCategory } from '../../actions/mediaAction'
const BASE_URL = getAppBaseUrl()

const useStyles = () => ({
    container: {
        padding: '10px'
    },

    row: {
        marginLeft: '0px',
        marginRight: '0px'
    },

    upload_input: {
        display: 'none'
    },

    btn_text: {
        marginRight: 'auto',
        textTransform: 'none !important'
    },

    upload_button: {
        backgroundColor: 'rgb(236, 236, 236)',
        height: '40px',
        borderRadius: '20px',
        padding: '5px'
    },

    selectForm: {
        padding: '5px',
        height: '40px',
        border: 'none'
    },

    post_button: {
        color: 'white'
    }
})

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

const DatePickerDiv = styled.div`
    .react-datepicker__input-time-container .react-datepicker-time__input-container
    .react-datepicker-time__input input {
        width: auto;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker-input {
        text-align: center;
    }
`

const ButtonGroup = styled.div`
    width:100%;

    button.btn.mb-2.p-2.shadow-none.m-0:hover {
        background-color: ${props => props.content_color};
        color: ${props => props.content_font_color} !important;
        outline: 1px solid ${props => props.content_color} !important;
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.m-0.border-2:focus {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }
    button.btn.selectedOption {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 5px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.shadow-none.m-0:focus-visible, button.btn.mb-2.p-2.shadow-none.m-0:focus {
        outline: transparent !important;
    }

    @media (max-width: 1279px) and (min-width: 991px) {
        button.btn.mb-2.p-2.shadow-none.m-0 {
            font-size: 0.7125rem;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;

        .selectedOption {
            flex-basis: auto;
        }

        button.btn.mb-2.p-2.shadow-none.m-0 {
            display: block;
            width: 100% !important;
            min-height: auto;
        }

        button.rounded-corners {
            border: 2px solid ${props => props.content_color} !important;
            border-radius: 5px !important;
        }
    }

    .left-icon {
        float: left;
    }
    
    @media (min-width: 767px) {
        .desktop-text {
            position: relative;
            left: 74px;
        }
    }

    @media (max-width: 767px) {
        .justify-center {
            justify-content: center;
        }
    }

`
const ScheduleButtonDiv = styled.div`
    width: 100%;
`
class PostBlogForm extends React.Component {
    constructor() {
        super()
        this.state = {
            option: 'Locked',
            postType: 'gallery',
            uploading: false,
            gallery: [],
            description: '',
            isSchedule: false,
            privateOptionDlg: false,
            privateBlur: 'NOT_BLUR',
            selectedFile: null,
            title: '',
            author: '',
            uploadProgress: 0,
            fileName: '',
            renderFile: '',
            schedulePostTime: new Date().setDate(new Date().getDate() + 1),
            amount: 20,
            captionBlur: false,
            schedulePostHour: new Date().getHours() > 12 ? new Date().getHours() - 12 : new Date().getHours(),
            schedulePostMin: new Date().getMinutes(),
            schedulePostTimeFormat: new Date().getHours() > 12 ? 'PM' : 'AM',
            showVideoLength: false,
            showNumberOfGalleryImage: false,
            videoDuration: 0,
            previewIndex: '',
            feedTags: [],
            errorText: '',
            openHelperPopup: false,
            selectFromContentManager: true,
            selectedCategoryOption: null,
            totalMedia: 0,
            uploadedMediaCount: 0
        }

        this.handlePostSubmit = this.handlePostSubmit.bind(this)
        this.handleVideoUpload = this.handleVideoUpload.bind(this)
        this.handlePhotoChange = this.handlePhotoChange.bind(this)
        this.handleVideoChange = this.handleVideoChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleIsScheduleChange = this.handleIsScheduleChange.bind(this)
        this.onGallerySelect = this.onGallerySelect.bind(this)
        this.handlePostGallerySubmit = this.handlePostGallerySubmit.bind(this)
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.setPreviewIndex = this.setPreviewIndex.bind(this)
        this.setPostType = this.setPostType.bind(this)
        this.onSelectPublicPost = this.onSelectPublicPost.bind(this)
        this.onSelectPrivatePost = this.onSelectPrivatePost.bind(this)
        this.setFeedTags = this.setFeedTags.bind(this)
        this.setSelectFromContentManager = this.setSelectFromContentManager.bind(this)
        this.setSelectedCategoryOption = this.setSelectedCategoryOption.bind(this)
        this.validateAndAssignSelectedCategory = this.validateAndAssignSelectedCategory.bind(this)
    }

    componentDidMount() {
        this.setState({ 'title': `Blog posted by ${this.props.auth.appSettings.model_name}`, author: `${this.props.auth.appSettings.model_name}` })
    }

    setSelectedCategoryOption(options) {
        this.setState({ selectedCategoryOption: options })
    }

    async handlePostSubmit(e) {
        e.preventDefault()
        let selectedFile = this.state.selectedFile

        if (this.props.gallery.files.length === 1) {
            selectedFile = this.props.gallery.files[0].selectedFile
        }

        if (selectedFile === null) {
            return this.props.setSweetAlert({ description: 'Please Select Media' })
        }

        let amount = this.state.amount
        let contentUnlockMinimumAmount = this.props.auth.appSettings.content_unlock_minimum_amount
        let contentUnlockMaximumAmount = this.props.auth.appSettings.content_unlock_maximum_amount

        if (((amount < contentUnlockMinimumAmount) || (amount > contentUnlockMaximumAmount)) && this.state.option === 'Locked') {
            return this.props.setSweetAlert({ description: `Choose an amount between ${contentUnlockMinimumAmount} and ${contentUnlockMaximumAmount}` })
        }

        if (selectedFile !== null) {
            let fileExtension = getFileExtension(selectedFile.name)
            fileExtension = fileExtension.toUpperCase()
            let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG']

            if (!acceptedFileExtensions.includes(fileExtension)) {
                this.setState({ selectedFile: null, postType: '' })
                return this.props.setSweetAlert({ description: 'Media format is not supported.' })
            }

            let schedulePostTime = this.getFormattedDateTime()

            let blog = {
                title: this.state.title,
                blogType: 'photo',
                description: this.state.description,
                author: this.state.author,
                public: this.state.option === 'Public' ? true : false,
                privateBlur: this.state.privateBlur === 'BLUR' ? true : false,
                processing: true,
                isLocked: this.state.option === 'Locked' ? true : false,
                amount: this.state.amount,
                model: this.props.auth.appSettings.model_id,
                captionBlur: this.state.captionBlur,
                date: new Date(),
                isPreviewEnable: false,
                contentLeftForProcessing: 1,
                blogSubType: 'photo',
                showNumberOfGalleryImage: false,
                show_content_length: false,
                media_preview: [],
                selectFromContentManager: this.state.selectFromContentManager
            }
            const [isCategoryInvalid, message] = this.validateAndAssignSelectedCategory(blog)
            if (isCategoryInvalid) {
                this.props.setSweetAlert({ description: message })
                return
            }

            this.setState({
                uploading: true
            })

            if (this.state.feedTags.length > 0 && this.props.auth.appSettings.feed_tags_enable) {
                blog.feed_tags = this.state.feedTags
            }

            if (this.state.isSchedule === true) {
                let scheduled_post_time = moment(schedulePostTime).utc()
                scheduled_post_time = new Date(schedulePostTime)

                blog.date = scheduled_post_time
            }

            if (selectedFile.format === 'modern') {
                blog.media_item = [selectedFile._id]
            } else {
                this.setState(prevState => ({
                    uploadedMediaCount: prevState.uploadedMediaCount + 1
                }))
                let presignedUrlData = await getPresignedUrl(selectedFile.name, 'blog', 'photo')
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await this.uploadFileUsingPresignedUrl('photo', presignedUrlData.presigned_url, selectedFile)
                    if (uploadFile === true) {
                        this.props.updateMediaCountInCategory({ slug: 'all media', mediaType: 'photo' })
                        blog.url = presignedUrlData.file_name
                        blog.media = [{
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            blur_url: '',
                            is_process: true,
                            content_type: 'photo',
                            is_error: false
                        }]
                    } else {
                        this.setState({ uploading: false })
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return this.props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    this.setState({ uploading: false })
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return this.props.setSweetAlert({ description: presignedUrlData.message })
                }
            }

            // preview image upload
            for (let index = 0; index < this.props.gallery.previewFiles.length; index++) {

                let blogUploadProgress = this.state.uploadProgress
                blogUploadProgress = blogUploadProgress + 1
                this.setState({ uploadProgress: blogUploadProgress })
                const element = this.props.gallery.previewFiles[index]

                let fileExtension = getFileExtension(element.selectedFile.name)
                let fileExtensionForPresignedUrl = 'photo'
                if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                    fileExtensionForPresignedUrl = 'video'
                }

                if (element.selectedFile.format === 'modern') {
                    blog.media_preview_item = [element.selectedFile._id]
                } else {
                    this.setState(prevState => ({
                        uploadedMediaCount: prevState.uploadedMediaCount + 1
                    }))
                    let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
                    if (presignedUrlData.presigned_url !== '') {
                        let uploadFile = await this.uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                        if (uploadFile === true) {
                            this.props.updateMediaCountInCategory({ slug: 'preview', mediaType: fileExtensionForPresignedUrl })
                            blog.media_preview.push({
                                url: presignedUrlData.file_name,
                                thumbnail_url: '',
                                is_process: true,
                                content_type: fileExtensionForPresignedUrl
                            })
                            blog.contentLeftForProcessing += 1
                        } else {
                            this.setState({ uploading: false })
                            setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                            return this.props.setSweetAlert({ description: presignedUrlData.message })
                        }
                    } else {
                        this.setState({ uploading: false })
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return this.props.setSweetAlert({ description: presignedUrlData.message })
                    }
                }
            }

            this.props.newPostNewBlog(blog, () => {
                this.resetFormState()
            })
        }
    }

    onGallerySelect(e) {
        e.preventDefault()
        this.setState({
            postType: 'gallery'
        })
    }

    handlePhotoChange(e) {
        if (e.target.files[0]) {
            this.setState({
                postType: 'photo',
                selectedFile: e.target.files[0],
                renderFile: URL.createObjectURL(e.target.files[0])
            })
        }
    }

    handleVideoChange(e) {
        if (e.target.files[0]) {
            this.setState({
                postType: 'video',
                selectedFile: e.target.files[0],
                renderFile: URL.createObjectURL(e.target.files[0])
            })
        }
    }

    setPostType(type) {
        this.setState({ postType: type })
    }

    onChange(e) {
        let value = e.target.value

        if (value === 'true') {
            value = true
        }

        if (value === 'false') {
            value = false
        }

        this.setState({ [e.target.name]: value })
        if (e.target.name === 'option') {
            this.setState({ previewIndex: '' })
        }
    }

    handleIsScheduleChange() {
        this.setState({ isSchedule: !this.state.isSchedule })
    }

    async handleVideoUpload(e) {
        e.preventDefault()
        let selectedFile = this.state.selectedFile

        if (this.props.gallery.files.length === 1) {
            selectedFile = this.props.gallery.files[0].selectedFile
        }

        if (selectedFile === null) {
            return this.props.setSweetAlert({ description: 'Please Select Media' })
        }

        let amount = this.state.amount
        let contentUnlockMinimumAmount = this.props.auth.appSettings.content_unlock_minimum_amount
        let contentUnlockMaximumAmount = this.props.auth.appSettings.content_unlock_maximum_amount

        if (((amount < contentUnlockMinimumAmount) || (amount > contentUnlockMaximumAmount)) && this.state.option === 'Locked') {
            return this.props.setSweetAlert({ description: `Choose an amount between ${contentUnlockMinimumAmount} and ${contentUnlockMaximumAmount}` })
        }

        let fileExtension = getFileExtension(selectedFile.name)
        let upperCaseFileExtension = fileExtension.toUpperCase()
        let acceptedFileExtensions = ['MOV', 'MP4']

        if (!acceptedFileExtensions.includes(upperCaseFileExtension)) {
            this.setState({ selectedFile: null, postType: '' })
            return this.props.setSweetAlert({ description: 'Media format is not supported.' })
        }
        fileExtension = fileExtension.toLowerCase()
        let schedulePostTime = this.getFormattedDateTime()

        let blog = {
            title: this.state.title,
            blogType: 'video',
            description: this.state.description,
            author: this.state.author,
            public: this.state.option === 'Public' ? true : false,
            privateBlur: this.state.privateBlur === 'BLUR' ? true : false,
            processing: true,
            isLocked: this.state.option === 'Locked' ? true : false,
            amount: this.state.amount,
            model: this.props.auth.appSettings.model_id,
            captionBlur: this.state.captionBlur,
            date: new Date(),
            isPreviewEnable: false,
            contentLeftForProcessing: 1,
            blogSubType: 'video',
            showNumberOfGalleryImage: false,
            show_content_length: this.state.showVideoLength,
            media_preview: [],
            feed_tags: this.state.feedTags
        }
        const [isCategoryInvalid, message] = this.validateAndAssignSelectedCategory(blog)
        if (isCategoryInvalid) {
            this.props.setSweetAlert({ description: message })
            return
        }

        this.setState({
            uploading: true
        })

        if (this.state.isSchedule === true) {
            let scheduled_post_time = moment(schedulePostTime).utc()
            scheduled_post_time = new Date(schedulePostTime)

            blog.date = scheduled_post_time
        }

        if (selectedFile.format === 'modern') {
            blog.media_item = [selectedFile._id]
        } else {
            this.setState(prevState => ({
                uploadedMediaCount: prevState.uploadedMediaCount + 1
            }))
            let presignedUrlData = await getPresignedUrl(selectedFile.name, 'blog', 'video')
            if (presignedUrlData.presigned_url !== '') {
                let uploadFile = await this.uploadFileUsingPresignedUrl('video', presignedUrlData.presigned_url, selectedFile)
                if (uploadFile === true) {
                    this.props.updateMediaCountInCategory({ slug: 'all media', mediaType: 'video' })
                    blog.url = presignedUrlData.file_name
                    blog.media = [{
                        url: presignedUrlData.file_name,
                        thumbnail_url: '',
                        blur_url: '',
                        is_process: true,
                        content_type: 'video',
                        is_error: false
                    }]
                } else {
                    this.setState({ uploading: false })
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return this.props.setSweetAlert({ description: presignedUrlData.message })
                }
            } else {
                this.setState({ uploading: false })
                setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                return this.props.setSweetAlert({ description: presignedUrlData.message })
            }
        }

        // preview image upload
        for (let index = 0; index < this.props.gallery.previewFiles.length; index++) {

            let blogUploadProgress = this.state.uploadProgress
            blogUploadProgress = blogUploadProgress + 1
            this.setState({ uploadProgress: blogUploadProgress })
            const element = this.props.gallery.previewFiles[index]

            let fileExtension = getFileExtension(element.selectedFile.name)
            let fileExtensionForPresignedUrl = 'photo'
            if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                fileExtensionForPresignedUrl = 'video'
            }

            if (selectedFile.format === 'modern') {
                blog.media_preview_item = [element.selectedFile._id]
            } else {
                this.setState(prevState => ({
                    uploadedMediaCount: prevState.uploadedMediaCount + 1
                }))
                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await this.uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                    if (uploadFile === true) {
                        this.props.updateMediaCountInCategory({ slug: 'preview', mediaType: fileExtensionForPresignedUrl })
                        blog.media_preview.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl
                        })
                        blog.contentLeftForProcessing += 1
                    } else {
                        this.setState({ uploading: false })
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return this.props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    this.setState({ uploading: false })
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return this.props.setSweetAlert({ description: presignedUrlData.message })
                }
            }
        }

        this.props.newPostNewBlog(blog, () => {
            this.resetFormState()
        })
    }

    resetFormState() {
        this.setState({
            option: 'Locked',
            postType: '',
            uploading: false,
            gallery: [],
            description: '',
            isSchedule: false,
            privateOptionDlg: false,
            privateBlur: 'NOT_BLUR',
            selectedFile: null,
            uploadProgress: 0,
            captionBlur: false,
            showVideoLength: false,
            showNumberOfGalleryImage: false,
            amount: 20,
            feedTags: [],
            selectedCategoryOption: null,
            totalMedia: 0,
            uploadedMediaCount: 0,
            selectFromContentManager: true
        })

        this.props.galleryReset()
        this.props.previewGalleryReset()
        if (this.props.gallery.isPreviewModeEnable === true) {
            this.props.setIsPreviewModeEnable()
        }
    }

    async handlePostGallerySubmit(e) {
        e.preventDefault()
        if (this.state.description.trim() === '') {
            return this.props.setSweetAlert({ description: 'Please Add Caption' })
        }
        if (this.props.gallery.files.length === 0) {
            return this.props.setSweetAlert({ description: 'Please Select Media' })
        }

        if (this.props.gallery.files.length === 1) {
            let fileExtension = getFileExtension(this.props.gallery.files[0].selectedFile.name)

            if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                return this.handleVideoUpload(e)
            }
            return this.handlePostSubmit(e)
        }

        let amount = this.state.amount
        let contentUnlockMinimumAmount = this.props.auth.appSettings.content_unlock_minimum_amount
        let contentUnlockMaximumAmount = this.props.auth.appSettings.content_unlock_maximum_amount
        if (((amount < contentUnlockMinimumAmount) || (amount > contentUnlockMaximumAmount)) && this.state.option === 'Locked') {
            return this.props.setSweetAlert({ description: `Choose an amount between ${contentUnlockMinimumAmount} and ${contentUnlockMaximumAmount}` })
        }

        let photo = 0
        let video = 0
        for (let index = 0; index < this.props.gallery.files.length; index++) {
            const element = this.props.gallery.files[index]
            let fileExtension = getFileExtension(element.selectedFile.name)
            fileExtension = fileExtension.toUpperCase()
            let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
            if (['PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                photo += 1
            } else {
                video += 1
            }
            if (!acceptedFileExtensions.includes(fileExtension)) {
                this.setState({ selectedFile: null, postType: '' })
                return this.props.setSweetAlert({ description: `Image Number ${index + 1} media format is not supported.` })
            }
        }

        let galleryArray = [...this.props.gallery.files]
        let isPreviewEnable = false
        if (this.state.previewIndex !== '' && this.state.option === 'Locked') {
            galleryArray = []
            isPreviewEnable = true
            galleryArray.push(this.props.gallery.files[this.state.previewIndex])
            for (let index = 0; index < this.props.gallery.files.length; index++) {
                if (index !== Number(this.state.previewIndex)) {
                    galleryArray.push(this.props.gallery.files[index])
                }
            }
            this.props.dragDropImage(galleryArray)
        }

        let schedulePostTime = this.getFormattedDateTime()
        let blogSubType = ''
        if (photo > 0 && video > 0) {
            blogSubType = 'mixGallery'
        } else if (photo > 0 && video === 0) {
            blogSubType = 'gallery'
        } else if (photo === 0 && video > 0) {
            blogSubType = 'videoGallery'
        }

        const obj = {
            title: this.state.title,
            blogType: 'gallery',
            description: this.state.description,
            author: this.state.author,
            public: (this.state.option === 'Public' ? true : false),
            privateBlur: (this.state.privateBlur === 'BLUR' ? true : false),
            processing: true,
            isLocked: (this.state.option === 'Locked' ? true : false),
            amount: this.state.amount,
            model: this.props.auth.appSettings.model_id,
            captionBlur: this.state.captionBlur,
            contentLeftForProcessing: this.props.gallery.files.length + this.props.gallery.previewFiles.length,
            gallery: [],
            blurUrlGallery: [],
            thumbnailUrlGallery: [],
            date: new Date(),
            isPreviewEnable: isPreviewEnable,
            blogSubType: blogSubType,
            show_content_length: this.state.showNumberOfGalleryImage,
            showNumberOfGalleryImage: this.state.showNumberOfGalleryImage,
            media: [],
            media_preview: [],
            feed_tags: this.state.feedTags,
            selectFromContentManager: this.state.selectFromContentManager
        }
        const [isCategoryInvalid, message] = this.validateAndAssignSelectedCategory(obj)
        if (isCategoryInvalid) {
            this.props.setSweetAlert({ description: message })
            return
        }

        this.setState({
            uploading: true
        })

        if (this.state.isSchedule === true) {
            let scheduled_post_time = moment(schedulePostTime).utc()
            scheduled_post_time = new Date(schedulePostTime)

            obj.date = scheduled_post_time
        }

        let media_item = []
        for (let index = 0; index < galleryArray.length; index++) {

            let blogUploadProgress = this.state.uploadProgress
            blogUploadProgress = blogUploadProgress + 1
            this.setState({ uploadProgress: blogUploadProgress })
            const element = galleryArray[index]

            let fileExtension = getFileExtension(element.selectedFile.name)
            let fileExtensionForPresignedUrl = 'photo'
            if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                fileExtensionForPresignedUrl = 'video'
            }

            if (element.selectedFile.format === 'modern') {
                media_item.push(element.selectedFile._id)
                obj.media_item = media_item
            } else {
                this.setState(prevState => ({
                    uploadedMediaCount: prevState.uploadedMediaCount + 1
                }))
                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await this.uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index)
                    if (uploadFile === true) {
                        this.props.updateMediaCountInCategory({ slug: 'all media', mediaType: fileExtensionForPresignedUrl })
                        obj.gallery.push(presignedUrlData.file_name)
                        obj.blurUrlGallery.push('')
                        obj.thumbnailUrlGallery.push('')
                        obj.media.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            blur_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl,
                            is_error: false
                        })
                    } else {
                        this.setState({ uploading: false })
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return this.props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    this.setState({ uploading: false })
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return this.props.setSweetAlert({ description: presignedUrlData.message })
                }
            }

            if ((index + 1) === galleryArray.length && this.props.gallery.previewFiles.length === 0) {
                this.props.newPostNewBlog(obj, () => {
                    this.resetFormState()
                })
            }
        }

        // preview image upload
        for (let index = 0; index < this.props.gallery.previewFiles.length; index++) {

            let blogUploadProgress = this.state.uploadProgress
            blogUploadProgress = blogUploadProgress + 1
            this.setState({ uploadProgress: blogUploadProgress })
            const element = this.props.gallery.previewFiles[index]

            let fileExtension = getFileExtension(element.selectedFile.name)
            let fileExtensionForPresignedUrl = 'photo'
            if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                fileExtensionForPresignedUrl = 'video'
            }

            if (element.selectedFile.format === 'modern') {
                obj.media_preview_item = [element.selectedFile._id]
            } else {
                this.setState(prevState => ({
                    uploadedMediaCount: prevState.uploadedMediaCount + 1
                }))
                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await this.uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                    if (uploadFile === true) {
                        this.props.updateMediaCountInCategory({ slug: 'preview', mediaType: fileExtensionForPresignedUrl })
                        obj.media_preview.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl
                        })
                    } else {
                        this.setState({ uploading: false })
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return this.props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    this.setState({ uploading: false })
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return this.props.setSweetAlert({ description: presignedUrlData.message })
                }
            }

            this.props.newPostNewBlog(obj, () => {
                this.resetFormState()
            })
        }
    }

    async uploadFileUsingPresignedUrl(contentType, url, body, galleryIndex = 0, type = 'original') {

        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                const that = this
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    that.setState({ uploading: false })
                    return that.props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })
                const object = {
                    progress: percentCompleted + '%',
                    index: galleryIndex,
                    progressNumber: percentCompleted,
                    type: type
                }
                this.props.updateGalleryProgress(object)
            },
            cancelToken: cancelTokenSource.token
        }

        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    async uploadGalleryImage(galleryObject) {
        let res = await axios.post(BASE_URL + '/api/upload/upload_gallery_file', galleryObject)
        if (res.data.uploaded === true) {
            this.props.addNewBlog(res.data.data)
        }
        return new Promise((resolve) => {
            resolve()
        })
    }

    dateOnChange(date) {
        this.setState({ schedulePostTime: date })
    }

    // Convert 12-hour format to 24-hour format and set time in date object
    getFormattedDateTime() {
        let schedulePostTime = new Date(this.state.schedulePostTime)
        let hourWithFormat = this.state.schedulePostHour + '' + this.state.schedulePostTimeFormat
        let hour = moment(hourWithFormat, ['HHA']).format('HH')
        schedulePostTime.setHours(hour)
        schedulePostTime.setMinutes(this.state.schedulePostMin)
        return new Date(schedulePostTime).toISOString()
    }

    handleCheckBoxChange(e) {
        this.setState({ [e.target.name]: e.target.checked })
    }

    setPreviewIndex(index) {
        let newIndex = this.state.previewIndex === index.toString() ? '' : index.toString()
        this.setState({ previewIndex: newIndex })
    }

    showVideoLengthOption() {
        let fileExtension = getFileExtension(this.props.gallery.files[0].selectedFile.name)
        if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
            return <div className='form-group'>
                <div className='form-check form-check-inline'>
                    <input
                        type='checkbox'
                        className='form-check-input'
                        name='showVideoLength'
                        id='show-video-length'
                        checked={this.state.showVideoLength}
                        onChange={this.handleCheckBoxChange}
                    />
                    <label className='form-check-label' htmlFor='show-video-length'>
                        Show length of video
                    </label>
                </div>
            </div>
        }
    }

    onSelectPublicPost() {
        this.setState({
            option: 'Public',
            privateBlur: 'NOT_BLUR',
            captionBlur: false,
            showVideoLength: false,
            showNumberOfGalleryImage: false
        })
        this.props.previewGalleryReset()
    }

    onSelectPrivatePost() {
        this.setState({
            option: 'Private',
            showVideoLength: false,
            showNumberOfGalleryImage: false
        })
        this.props.previewGalleryReset()
    }

    setFeedTags(feedTags) {
        this.setState({ 'errorText': '' })
        let selectedTags = []
        for (const tag of feedTags) {
            const slug = tag.value.trim().toLowerCase()
            const isNew = _.get(tag, '__isNew__', false)
            if (slug === '') {
                this.setState({ 'errorText': 'Tag not allow empty' })
            } else if (isNew) {
                const isValid = this.props.auth.appSettings.feed_tags.find(item => item.slug === slug)
                if (isValid !== undefined) {
                    this.setState({ 'errorText': 'Tag already added' })
                } else {
                    selectedTags.push(tag)
                }
            } else {
                selectedTags.push(tag)
            }
        }
        this.setState({ 'feedTags': selectedTags })
    }

    setSelectFromContentManager(isSelected) {
        if (this.state.selectFromContentManager !== isSelected) {
            this.props.switchGallery()
            this.setState({ selectFromContentManager: isSelected })
        }
    }

    /**
     * Validate and assign selected category to passed object
     * @param {object} blog - The blog details which is going to be saved in database
     * @returns {boolean} Defines whether category is invalid or not, returns true if category is invalid otherwise returns false
     */
    validateAndAssignSelectedCategory(blog) {
        const selectedCategoryOption = this.state.selectedCategoryOption
        const selectedCategory = selectedCategoryOption ? selectedCategoryOption.label : ''
        const isNewCategory = (selectedCategoryOption && selectedCategoryOption.__isNew__) ? true : false
        const selectedCategoryId = selectedCategoryOption ? selectedCategoryOption.value : ''
        let isCategoryInvalid = false
        let errorMessage = 'Invalid Category'
        if (selectedCategory.length > 30) {
            errorMessage = 'Category name cannot be more than 30 characters'
            isCategoryInvalid = true
            return [isCategoryInvalid, errorMessage]
        }
        // Check if user is not adding category with same name as old one
        if (isNewCategory) {
            const category = this.props.media.category
            for (let i = 0; i < category.length; i++) {
                if (category[i].slug === selectedCategory.trim().toLowerCase()) {
                    isCategoryInvalid = true
                    break
                }
            }
        }
        blog.category = {
            id: selectedCategoryId,
            name: selectedCategory,
            isNew: isNewCategory
        }
        return [isCategoryInvalid, errorMessage]
    }

    render() {
        const {
            content_color,
            content_font_color,
            site_font_color,
            enable_public_post,
            button_shadow_color,
            feed_tags_enable
        } = this.props.auth.appSettings
        let hourArray = new Array(12).fill(0)
        let minArray = new Array(60).fill(0)
        // let preview
        // if (this.state.postType === 'photo') {
        //     preview = <img draggable={false} src={this.state.renderFile} alt='' style={{ height: '200px', objectFit: 'contain' }} />
        // }
        // else if (this.state.postType === 'video') {
        //     preview = <video src={this.state.renderFile} id='uploaded-video' controls style={{ height: '30vh', maxWidth: '100%' }} />
        // }

        return (
            <WebsiteFontColor>
                <CardRow title=''>
                    <div className='row'>
                        <div className='col'>
                            <div style={{ padding: '10px 0px', position: 'relative' }}>
                                <form>
                                    <div className='form-group'>
                                        <textarea
                                            id='description'
                                            name='description'
                                            className='form-control'
                                            rows='3'
                                            value={this.state.description}
                                            onChange={this.onChange}
                                            placeholder='Say what is on your mind....'
                                            disabled={this.state.uploading}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <span>Select Media from:</span>
                    <ButtonGroup className='button-group d-flex mt-2 mb-4' content_color={content_color} content_font_color={content_font_color} button_shadow_color={button_shadow_color}>
                        <button type='button'
                            className={classNames('btn col mb-2 p-2 m-0 rounded-start shadow-none w-100 position-relative',
                                {
                                    'border-2 selectedOption': this.state.selectFromContentManager,
                                    'shadow-none': this.state.selectFromContentManager
                                }
                            )}
                            onClick={() => this.setSelectFromContentManager(true)}
                            style={{
                                borderRadius: '0% 0% 0% 0%',
                                backgroundColor: this.state.selectFromContentManager && content_color,
                                color: this.state.selectFromContentManager ? content_font_color : site_font_color,
                                borderColor: !this.state.selectFromContentManager && content_color
                            }}
                            disabled={this.state.uploading}
                        >
                            <i className='fas fa-photo-video mr-2 float-start position-absolute' style={{
                                left: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '18px'
                            }}></i>
                            <span className=''>CONTENT MANAGER</span>
                        </button>
                        <button type='button'
                            className={classNames('btn col mb-2 p-2 m-0 rounded-end shadow-none w-100 position-relative',
                                {
                                    'border-2 selectedOption': !this.state.selectFromContentManager,
                                    '': !this.state.selectFromContentManager
                                }
                            )}
                            onClick={() => this.setSelectFromContentManager(false)}
                            style={{
                                borderRadius: '0% 0% 0% 0%',
                                backgroundColor: !this.state.selectFromContentManager && content_color,
                                color: !this.state.selectFromContentManager ? content_font_color : site_font_color,
                                borderColor: this.state.selectFromContentManager && content_color
                            }}
                            disabled={this.state.uploading}
                        >
                            <i className='fas fa-upload mr-2 float-start position-absolute' style={{
                                left: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '18px'
                            }}></i>
                            <span className=''>UPLOAD NEW MEDIA</span>
                        </button>
                    </ButtonGroup>
                    <div className='row' style={{ textAlign: 'center' }}>
                        <div className='col-12 col-md-12'>
                            <NewGalleryMultiImageUpload
                                classes={this.props.classes}
                                disableButtons={this.state.uploading}
                                setIndex={this.setPreviewIndex}
                                previewIndex={this.state.previewIndex}
                                isLocked={this.state.option}
                                setPostType={this.setPostType}
                                selectFromContentManager={this.state.selectFromContentManager}
                                isUploading={this.state.uploading}
                                setSelectedCategoryOption={this.setSelectedCategoryOption}
                                selectedCategoryOption={this.state.selectedCategoryOption}
                            />
                        </div>
                    </div>
                    <ButtonGroup className='button-group d-flex mt-5' content_color={content_color} content_font_color={content_font_color} button_shadow_color={button_shadow_color}>
                        <button type='button'
                            className={classNames('btn col mb-2 p-2 m-0 rounded-start rounded-corners', { 'border-2 selectedOption': this.state.option === 'Locked', 'shadow-none': this.state.option !== 'Locked' })}
                            onClick={() => this.setState({ 'option': 'Locked' })}
                            style={{
                                borderRadius: '0% 0% 0% 0%',
                                backgroundColor: this.state.option === 'Locked' && content_color,
                                color: this.state.option === 'Locked' ? content_font_color : site_font_color,
                                borderColor: this.state.option !== 'Locked' && content_color
                            }}
                            disabled={this.state.uploading}
                        >
                            <AttachMoney className='left-icon' style={{ fontSize: '25px', verticalAlign: 'middle' }} /> PAYMENT REQUIRED
                        </button>
                        <button type='button'
                            className={classNames('btn col mb-2 p-2 m-0 rounded-0 rounded-corners', { 'border-2 selectedOption': this.state.option === 'Private', 'shadow-none': this.state.option !== 'Private' })}
                            onClick={this.onSelectPrivatePost}
                            style={{
                                borderRadius: '0% 0% 0% 0%',
                                backgroundColor: this.state.option === 'Private' && content_color,
                                color: this.state.option === 'Private' ? content_font_color : site_font_color,
                                borderColor: this.state.option !== 'Private' && content_color
                            }}
                            disabled={this.state.uploading}
                        >
                            <PeopleIcon className='left-icon' style={{ fontSize: '25px', verticalAlign: 'middle' }} /> FREE FOR SUBSCRIBERS
                        </button>
                        {enable_public_post === true &&
                            <button type='button'
                                className={classNames('btn col mb-2 p-2 m-0 border-left-0 rounded-end rounded-corners', { 'border-2 selectedOption': this.state.option === 'Public', 'shadow-none': this.state.option !== 'Public' })}
                                onClick={this.onSelectPublicPost}
                                style={{
                                    borderRadius: '0% 0% 0% 0%',
                                    backgroundColor: this.state.option === 'Public' && content_color,
                                    color: this.state.option === 'Public' ? content_font_color : site_font_color,
                                    borderColor: this.state.option !== 'Public' && content_color
                                }}
                                disabled={this.state.uploading}
                            >
                                <PublicIcon className='left-icon' style={{ fontSize: '25px', verticalAlign: 'middle' }} /> PUBLIC
                            </button>
                        }
                    </ButtonGroup>
                    <div className='row'>
                        <div className='col-md-12 col-12 mt-1'>
                            {this.state.isSchedule ?
                                <>
                                    <label className='mb-1' style={{ display: 'flex', alignItems: 'center', margin: '0', paddingTop: '10px' }}>Date</label>
                                    <div className='ml-1'>
                                        <DatePickerDiv>
                                            <DatePicker
                                                selected={this.state.schedulePostTime}
                                                onChange={date => this.dateOnChange(date)}
                                                minDate={new Date()}
                                                className={'form-control react-datepicker-input'}
                                                dateFormat='MMMM d, yyyy'
                                                disabled={this.state.uploading}
                                            />
                                        </DatePickerDiv>
                                    </div>
                                    <div className='whole-time-box pt-2'>
                                        <label className='mb-1' style={{ display: 'flex', alignItems: 'center', margin: '0' }}>Time</label>
                                        <div className='schedule-box' style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <select name='schedulePostHour' value={this.state.schedulePostHour} onChange={this.onChange} disabled={this.state.uploading} className='form-control ml-1 form-select' style={{ textAlignLast: 'center' }}>
                                                {hourArray.map((d, i) => {
                                                    return <option key={'hour' + i} value={i + 1}>{i + 1}</option>
                                                })
                                                }
                                            </select>
                                            <select name='schedulePostMin' value={this.state.schedulePostMin} onChange={this.onChange} disabled={this.state.uploading} className='form-control ml-1 form-select' style={{ textAlignLast: 'center' }}>
                                                {minArray.map((d, i) => {
                                                    return <option key={'min' + i} value={i}>{i < 10 ? '0' + (i) : i}</option>
                                                })
                                                }
                                            </select>
                                            <select name='schedulePostTimeFormat' value={this.state.schedulePostTimeFormat} onChange={this.onChange} disabled={this.state.uploading} className='form-control ml-1 form-select' style={{ textAlignLast: 'center' }}>
                                                <option value='AM'>AM</option>
                                                <option value='PM'>PM</option>
                                            </select>
                                        </div>
                                        <div className='my-3'>
                                            <button className='btn' onClick={this.handleIsScheduleChange} style={{ backgroundColor: content_color, color: content_font_color }} disabled={this.state.uploading}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                                :
                                <ScheduleButtonDiv>
                                    <Button
                                        onClick={this.handleIsScheduleChange}
                                        style={{
                                            textTransform: 'none',
                                            backgroundColor: content_color,
                                            color: content_font_color,
                                            boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                        }}
                                        disabled={this.state.uploading}
                                        className='btn mt-2 mb-4 px-3'
                                    >
                                        <AlarmIcon style={{ fontSize: '30px' }} />
                                        &nbsp;Schedule Post
                                    </Button>
                                </ScheduleButtonDiv>
                            }
                        </div>
                    </div >
                    {
                        this.state.option !== 'Public' ?
                            <>
                                <label>Blur Photo/Video</label>
                                <div className='form-group'>
                                    <div className='from-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            value='BLUR'
                                            checked={this.state.privateBlur === 'BLUR'}
                                            onChange={this.onChange}
                                            name='privateBlur'
                                            id='PHOTO_BLUR'
                                            disabled={this.state.uploading}
                                        />
                                        <label className='form-check-label' htmlFor='PHOTO_BLUR'>Enable Blur</label>
                                    </div>
                                    <div className='from-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            value='NOT_BLUR'
                                            checked={this.state.privateBlur === 'NOT_BLUR'}
                                            onChange={this.onChange}
                                            name='privateBlur'
                                            id='PHOTO_NOT_BLUR'
                                            disabled={this.state.uploading}
                                        />
                                        <label className='form-check-label' htmlFor='PHOTO_NOT_BLUR'>Disable Blur</label>
                                    </div>
                                </div>
                                <label>Blur Caption</label>
                                <div className='form-group'>
                                    <div className='from-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            value={true}
                                            checked={this.state.captionBlur === true}
                                            onChange={this.onChange}
                                            name='captionBlur'
                                            id='CAPTION_BLUR'
                                            disabled={this.state.uploading}
                                        />
                                        <label className='form-check-label' htmlFor='CAPTION_BLUR'>Enable Blur</label>
                                    </div>
                                    <div className='from-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            value={false}
                                            checked={this.state.captionBlur === false}
                                            onChange={this.onChange}
                                            name='captionBlur'
                                            id='CAPTION_NOT_BLUR'
                                            disabled={this.state.uploading}
                                        />
                                        <label className='form-check-label' htmlFor='CAPTION_NOT_BLUR'>Disable Blur</label>
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {
                        this.state.option === 'Locked' ?
                            <div className='form-row'>
                                <div className='form-group col col-md-6 col-lg-6'>
                                    <label htmlFor='amount'>Amount</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        id='amount'
                                        name='amount'
                                        value={this.state.amount}
                                        onChange={this.onChange}
                                        required
                                        min={5}
                                        max={450}
                                        disabled={this.state.uploading}
                                    />
                                </div>
                            </div>
                            : null
                    }
                    {
                        this.state.option === 'Locked' &&
                        <>
                            {this.props.gallery.files.length === 1 &&
                                this.showVideoLengthOption()
                            }
                        </>
                    }
                    {feed_tags_enable && <div className='form-row'>
                        <div className='form-group col col-md-6 col-lg-6'>
                            <label htmlFor='feed-tag'>Tags</label>
                            <HelperButton disabled={this.state.uploading} style={{ color: content_color, fontSize: '18px', backgroundColor: 'transparent' }} onClick={() => this.setState({ openHelperPopup: true })}>
                                <i className='fas fa-question-circle'></i>
                            </HelperButton>
                            <CreatableSelect
                                className='text-dark'
                                isMulti={true}
                                value={this.state.feedTags}
                                options={this.props.blog.tagList}
                                onChange={(tags) => this.setFeedTags(tags)}
                                noOptionsMessage={() => 'No Tags Available'}
                                isDisabled={this.state.uploading}
                            />
                            {this.state.errorText !== '' &&
                                <div className='invalid-feedback d-block'>{this.state.errorText}</div>
                            }
                        </div>
                    </div>}
                    <div className='row' style={{ textAlign: 'center' }}>
                        <div className='col'>
                            <button className='btn btn-block'
                                onClick={this.handlePostGallerySubmit}
                                style={{
                                    backgroundColor: content_color,
                                    color: content_font_color,
                                    boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                }}
                                disabled={this.state.uploading}
                            >
                                {(this.state.uploading && this.state.selectFromContentManager === false) && (
                                    <>
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                        uploading
                                        &nbsp;{this.props.gallery.files.length + this.props.gallery.previewFiles.length > 0 && `${this.props.gallery.files.length + this.props.gallery.previewFiles.length === 1 ? 1 : this.state.uploadedMediaCount}/${this.props.gallery.files.length + this.props.gallery.previewFiles.length}`}
                                    </>
                                )}
                                {(this.state.selectFromContentManager === true && this.state.uploading) ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                        post
                                    </>
                                ) : (
                                    !this.state.uploading && 'post'
                                )}
                            </button>
                        </div>
                    </div>
                    {this.state.openHelperPopup && <ModalPopUp classes='modal-lg' closeBtnFontSize='initial' handleClose={() => { this.setState({ openHelperPopup: false }) }}>
                        <div className='row pr-4 pl-4'>
                            You can assign one or more tags to this upload.
                            Tags make it easy for both you and your subscribers to filter the content in the feed.
                            Each time you choose a tag from the dropdown it will show inside of the Tags field.
                            You can add more than one tag and you can also remove selected tags by hitting the &apos;x&apos; next to the tag name.
                            If you would like to add a new tag simply type the name of the tag and you will be given the opportunity to add the new tag.
                        </div>
                    </ModalPopUp>}
                </CardRow >
            </WebsiteFontColor >
        )
    }
}

PostBlogForm.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    newPostNewBlog: PropTypes.func.isRequired,
    uploadBlogImage: PropTypes.func.isRequired,
    gallery: PropTypes.shape({
        files: PropTypes.array.isRequired,
        previewFiles: PropTypes.array,
        isPreviewModeEnable: PropTypes.bool.isRequired
    }),
    addNewBlog: PropTypes.func.isRequired,
    galleryReset: PropTypes.func.isRequired,
    updateGalleryProgress: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func,
    dragDropImage: PropTypes.func,
    previewGalleryReset: PropTypes.func,
    setIsPreviewModeEnable: PropTypes.func,
    switchGallery: PropTypes.object.isRequired,
    updateMediaCountInCategory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    media: state.media,
    errors: state.errors,
    blog: state.blog,
    chat: state.chat,
    gallery: state.gallery
})

export default connect(
    mapStateToProps,
    {
        newPostNewBlog,
        uploadBlogImage,
        addNewBlog,
        galleryReset,
        updateGalleryProgress,
        setSweetAlert,
        dragDropImage,
        previewGalleryReset,
        setIsPreviewModeEnable,
        switchGallery,
        updateMediaCountInCategory
    }
)(withRouter(withStyles(useStyles)(PostBlogForm)))
